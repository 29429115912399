<template>
    <v-container fluid>
    <v-row>
        <v-col>
            <v-card>
                <v-container fluid>
                    <v-row>
                        <v-col cols="12">
                            <div class="text-center text-h4 pa-5">Balance Statement</div>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" lg="3" class="text-center text-lg-start">
                            <span class="text-h6">Name: {{statementDetails.people.name}}</span>
                        </v-col>
                        <v-col cols="12" lg="3" class="text-center text-lg-start">
                            <span class="text-h6">Contact No: {{statementDetails.people.contact_number}}</span>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" lg="3" class="text-center text-lg-start">
                            <span class="text-h6 green--text">Total Recieved: {{statementDetails.people.total_received}}</span>
                        </v-col>
                        <v-col cols="12" lg="3" class="text-center text-lg-start">
                            <span class="text-h6 red--text">Total Borrowed: {{statementDetails.people.total_borrowed}}</span>
                        </v-col>
                        <v-col cols="12" lg="3" class="text-center text-lg-start">
                            <span class="text-h6 blue--text">Balance: {{statementDetails.people.current_debit}}</span>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" class="text-center">
                            <v-data-table :headers="fields" :items="statementDetails.transactions.data" hide-default-footer mobile-breakpoint="0">
                                <template v-slot:[`item.amount`]="{ item }"><span :class="`${item.type==='received'?'green':'red'}--text`">{{item.amount}}</span></template>
                                <template v-slot:[`item.type`]="{ item }"><span>{{item.type.charAt(0).toUpperCase()}}{{item.type.slice(1)}}</span></template>
                            </v-data-table>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" class="text-center mb-5" >
                            <v-pagination v-model="statementDetails.transactions.current_page" :length="statementDetails.transactions.last_page" @next="currentPage()" @previous="currentPage()" @input="currentPage()"></v-pagination>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-overlay :value="loading" color="white">
                            <v-progress-circular
                                indeterminate
                                size="64"
                                color="primary"
                            ></v-progress-circular>
                            </v-overlay>
                        </v-col>
                    </v-row>
                </v-container>   
            </v-card>
        </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {get_people_statement} from '@api/people'
export default {
    data(){
        return {
            current_page:1,
            total_pages:1,
            fields:[
                {
                    text:"Date",
                    value:"date",
                },
                {
                    text:"Description",
                    value:"description",
                },
                { 
                    text:"Type",
                    value:"type",
                },
                { 
                    text:"Amount",
                    value:"amount",
                },
                {
                    text:"Balance",
                    value:"balance",
                },
            ],
            statementDetails:{
                people:{},
                transactions:{}
            },
            loading:false,
            errResp:''
        } 
    },
    mounted(){
        this.getPeopleStatement({id:this.$route.params.id})
    },
    methods: {
        getPeopleStatement(data){
            this.loading = true;
            this.errResp ='';
            get_people_statement(data).then(resp=>{
                this.loading = false;
                if(resp.status==='Success'){
                    this.statementDetails= resp.data;
                }else{
                    this.errResp= resp.errors;
                }   
            })
        },
        currentPage(){
            let data={
                page:Number(this.statementDetails.transactions.current_page)
            }
            this.getPeopleStatement({id:this.$route.params.id,data});
        },
    }
}
</script>

<style>

</style>